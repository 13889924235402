import http from '../index';

const BASEURI = 'patient/colonia';

const ColoniaApi = {
  // eslint-disable-next-line consistent-return
  getcolonia: async () => {
    try {
      const request = await http.get(BASEURI);
      const { data } = request;
      if (data) {
        return data;
      }
    } catch (error) {
      // console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  getcoloniaMunicipio: async (municipio) => {
    try {
      const request = await http.get(`${BASEURI}/municipio?municipio=${municipio}`);
      const { data } = request;
      if (data) {
        return data;
      }
    } catch (error) {
      // console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  getcoloniaCPId: async (cpId) => {
    try {
      const request = await http.get(`${BASEURI}/${cpId}`);
      const { data } = request;
      if (data) {
        return data;
      }
    } catch (error) {
      // console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  postcolonia: async (data) => {
    try {
      const request = await http.post(BASEURI, data);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
};

export default ColoniaApi;
