import http from '../index';

const BASEURI = 'patient/delegacion';

const delegacionApi = {
  // eslint-disable-next-line consistent-return
  getdelegacion: async (municipio) => {
    try {
      const request = await http.get(`${BASEURI}?municipio=${municipio}`);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // eslint-disable-next-line consistent-return
  postdelegacion: async (data) => {
    try {
      const request = await http.post(BASEURI, data);
      if (request) {
        return request;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
};

export default delegacionApi;
